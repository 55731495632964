@import "../globals.scss";

.Announcements{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative;

    .darkmode{
        background-color: #404040 !important;
        transition: all 0.5s;
    }
    .whitemode{
        color: #404040 !important;
    }

    .AnnouncementsMain{
        width: 90%;
        max-width: 1200px;
        min-height:300px;
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        transition: all 0.5s;
        padding: 40px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        @media screen and (max-width: 700px) {
         padding: 20px 10px;
         width: 85%;
        }
    }

    .Announcements_titleHeader{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Announcements_title{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 30px;
            font-weight: 600;
            color: cyan;
        }

        .LinktreeBtnDiv{
            display: flex;
            justify-content: center;
            align-items: centers;
            margin-top: 10px;
        }

        .LinktreeBtn{
            padding: 10px 15px;
            border-radius: 50px;
            background-color: #858585;
            font-size: 20px;
            font-weight: 700;
            color: white;
            cursor: pointer;
        }
    }
    

    .AnnouncementsImgSec{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        width: 100%;
        flex-wrap: wrap;
    }
    .AnnouncementsImgData{
        margin: 10px;
        width: 350px;
        height: 343px;
        cursor: pointer;
        border-radius: 10px;
    }
}