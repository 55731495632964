@import "../globals.scss";

.LatestVideo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative;

    .darkmode{
        background-color: #404040 !important;
        transition: all 0.5s;
    }

    .LatestVideoMain{
        width: 90%;
        max-width: 1200px;
        min-height:400px;
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        transition: all 0.5s;
        padding: 40px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        @media screen and (max-width: 700px) {
            padding: 20px 15px;
            width: 85%;
           }
    }

    .LatestVideoTitle{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #C995EB;
    }

    .LatestVideoVideoSec{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 10px;
        @media screen and (max-width: 900px) {
            flex-direction: column-reverse;
           }
    }
    .LatestVideoVideoSecleft{
        width: 50%;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 900px) {
            width: 100%;
           }
    }

    .LatestVideoVideoSecRight{
        width: 50%;
        height: 100%;
        @media screen and (max-width: 900px) {
            width: 100%;
           }
        .LatestVideoVideoSecRight_video{
            border-radius: 10px;
            width:100%;
            height:350px;
            @media screen and (max-width: 700px) {
                height:230px;
               }
        }
    }

    .LatestVideoVideoSecRight_imgVideo{
        height: 90px;
        width: 150px;
        border-radius: 10px;
    }

    .LatestVideoCard{
        display: flex;
        flex-direction: row;
        border: solid 2px #4E585E;
        padding: 10px;
        border-radius: 10px;
        width: 90%;
        background-color: #515050;
        margin: 5px 0px;
        transition: all 1s;
        @media screen and (max-width: 900px) {
            width: 95%;
           }


        .LatestVideoCard_img{
            img{
                width: 140px;
                height: 90px;
                object-fit: cover;
                border-radius: 10px;
            }
        }

        .LatestVideoCard_data{
            display: flex;
            flex-direction: column;
            padding: 8px 15px ;
            justify-content: center;

        }

        .LatestVideoCard_data_title{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18px;
            font-weight: 500;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis; 
        }

        .LatestVideoCard_data_name{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18px;
            font-weight: 600;
            margin-top: 3px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis; 
        }

        .LatestVideoCard_data_Date{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 16px;
            font-weight: 600;
            margin-top: 4px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis; 
        }



    }

    .wbgcolor{
        background-color: white;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border: 2px solid white;
        transition: all 1s;

    }
}