@import "../globals.scss";


.Videobanner{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative;

    .VideobannerMain{
        width: 100%;
        max-width: 1290px;
        min-height:330px;
        border-radius: 20px;
        transition: all 0.5s;
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media screen and (max-width: 700px) {
         width: 85%;
        }
        @media screen and (max-width: 1000px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
           }
    }

    .VideobannerMain_1{
        width: 25%;
        height: 300px;
        margin: 10px 10px;
        @media screen and (max-width: 1000px) {
            width: 98%;

           }
        .VideobannerMain_1img{
            width: 100%;
            height: 300px;

            border-radius: 10px;
            object-fit: cover;
        } 
      }

    .VideobannerMain_2{
        height: 300px;
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        border-radius: 10px;
        margin: 10px 10px;
        background: linear-gradient(232deg, #FF3C09 17.72%, #FF6880 128.62%);
        @media screen and (max-width: 1000px) {
            width: 98%;

           }

        .VideobannerMain_2title{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 36px;
            font-weight: 700;
            display: flex;
            text-align: center;
            align-items: center;
            color: white !important;
        }

        .VideobannerMain_2btn{
            background-color: black;
            border-radius: 50px;
            padding: 10px 20px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18px;
            font-weight: 600;
            margin-top: 13px;
            color: white;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            @media screen and (max-width: 400px) {
                padding: 10px 5px;
                width: 90%;
               }
        }
  
    }

    .navLinkDesign{
        text-decoration: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .VideobannerMain_3{
        height: 300px;
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin: 10px 10px;
        background: linear-gradient(180deg, #7D6CFC 0%, #B1B5E1 100%);
        @media screen and (max-width: 1000px) {
            width: 98%;
           }
        .VideobannerMain_3title{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 36px;
            font-weight: 700;
            display: flex;
            text-align: center;
            align-items: center;
            width: 80%;
            color: white !important;
        } 

        .VideobannerMain_3btn{
            background-color: white;
            border-radius: 50px;
            padding: 10px 30px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 20px;
            font-weight: 600;
            margin-top: 13px;
            color: black;
            cursor: pointer;
        }
      }
}