@import "../globals.scss";

.HistoryPage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .HistoryHeader {
        max-width: 760px; // Set maximum width for the content
        display: flex; // Make this a flex container
        flex-direction: column; // Stack children vertically
        align-items: center; // Center align the flex items
        text-align: center; // Center align the text
        padding: 20px; // Add some padding
    
        h2 {
          margin-bottom: 20px; // Space between the title and the paragraph
        }
      }
    

    .Historybody {
        display: flex;
        flex-direction: column; // Stack children vertically
        align-items: center; // Center align the flex items
        max-width: 1024px; // Ensure the buttons don't exceed the max width
        width: 100%;
        .BodyHeader{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 10px;

          .noBGlogo{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $secondary-300;
            border-radius: 50px;
            max-width: 300px;
            max-height: 200px;
          }
          .noBGlogo:hover{
            background-color: $accent-200;
          }
        }
        .tab-buttons {
          display: flex;
          justify-content: center; // Center buttons horizontally
          margin-bottom: 20px; // Space between buttons and content
          button {
            background-color: $FontWhiteColor;
            color: $primary-bl-100;
            border: 2px solid $secondary-300;
            padding: 10px 20px;
            margin: 0 5px; // Give equal space on both sides
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;
    
            &:hover {
              background-color: $primary-bl-100;
              color: $FontWhiteColor;
            }
          }
    
          .active {
            background-color: $primary-bl-100;
            color: $FontWhiteColor;
          }
        }
    
        .HistoryContent {
            width: 100%; // Ensure the content doesn't exceed the max width
            transition: opacity 0.3s, transform 0.3s;
          }
    
        .fade-enter {
            opacity: 0.01;
            transform: scale(0.95);
        }
    
        .fade-enter.fade-enter-active {
            opacity: 1;
            transform: scale(1);
            transition: opacity 300ms, transform 300ms;
        }
    
        .fade-exit {
            opacity: 1;
        }
    
        .fade-exit.fade-exit-active {
            opacity: 0;
            transition: opacity 300ms;
        }
    }
    
}