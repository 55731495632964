@import "../globals.scss";

.footer {
    background-color: #1a1a1a; // Dark background color
    color: white;
    padding: 2rem 0;
    font-family: 'Arial', sans-serif;

    .LinkScss{
      color: #00bfff;
      text-decoration: none;
      font-size: 1.2rem;
      display: block; // Ensures the entire area is clickable
      margin-bottom: 0.25rem;
    }
  
    .footer-content {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 1rem;
      flex-wrap: wrap;
    }
  
    .footer-section {
      flex: 1;
      min-width: 200px;
      margin-bottom: 1rem;
  
      .footer-heading {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        color: #00bfff; // Light blue color for headings
      }
  
      .footer-links {
        list-style: none;
        padding: 0;
  
        li a {
          color: white;
          text-decoration: none;
          font-size: 0.9rem;
          display: block; // Ensures the entire area is clickable
          margin-bottom: 0.25rem;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  
    .footer-info {
      text-align: center;
      font-size: 0.8rem;
      margin: 1rem 0;
    }
    .footer-credits {
        text-align: center;
        font-size: 0.8rem;
        margin: 1rem 0;
      }
    .footer-socialMediaBtns{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 20px;
    }
    .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 1rem;
        border-top: 1px solid #333;
      
        .footer-logo, .footer-info, .footer-policy, .footer-credits {
          flex: 1;
          min-width: 150px; 
          text-align: center;
          box-sizing: border-box;
        }
      
        .footer-logo img, .footer-credits .ASIimage img {
          max-width: 100%; 
          width: 60%;
          height: auto; // Keep image aspect ratio
        }
      
        .footer-credits {
          font-size: 0.8rem;
        }
      
        .ASIimage img {
          max-width: 100%; // Ensures the image is not wider than its container
        }
      
        // Media query for very small screens if needed
        @media (max-width: 480px) {
          .footer-logo, .footer-info, .footer-policy, .footer-credits {
            flex-basis: 100%; // Each takes the full width of the screen
            max-width: 100%; // Overrides any other max-width settings
            margin-bottom: 0.5rem; // Adds some space between stacked items
            text-align: center; // Center-aligns the text for small screens
      
            &:last-child {
              margin-bottom: 0; // No bottom margin for the last item
            }
          }
        }
      }
      
  
    @media (max-width: 768px) {
  
      .footer-section {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around; // Adjust as needed for spacing
        align-items: center; // Adjust vertical alignment as needed
        &:last-child {
          margin-bottom: 0;
        }
      }
  
      .footer-bottom {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        justify-content: space-around; // Adjust as needed
        align-items: center; // Adjust vertical alignment as needed
        text-align: center;
      }
    }
    @media screen and (max-width: 768px) {
        .footer-section ul, .footer-section li {
          // Hide subheadings in footer 
          display: none;
        }
      
        // .footer-info, .footer-policy {
        //   // Hide sections for smaller screens
        //   display: none;
        // }
      }
  }
  