@import "../globals.scss";


.Page404{

    margin-top:-2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .Page404_img{
        width: 70%;
        max-width: 900px;
        @media (max-width: 1025px) {
            margin-top: 100px;
            width: 95%;

          }

          @media (max-width: 600px) {
            margin-top: 130px;
            width: 95%;
          }
    }

 

    .Page404_imgtext{
        margin-top: 10px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
    }

    .homeReturn{
        padding: 10px 20px;
        font-size: 20px;
        border: 1px solid #4CC8F4;
        border-radius: 10px;
        margin: 0px 0 20px 0;
        cursor: pointer;
        font-weight: 600;
        text-decoration: none;
        color: var(--text-color);
         }

    .homeReturn:hover{
        animation: shake 0.5s;
        animation-iteration-count: infinite;
   
    }

      @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        10% { transform: translate(-1px, -2px) rotate(-1deg); }
        20% { transform: translate(-3px, 0px) rotate(1deg); }
        30% { transform: translate(3px, 2px) rotate(0deg); }
        40% { transform: translate(1px, -1px) rotate(1deg); }
        50% { transform: translate(-1px, 2px) rotate(-1deg); }
        60% { transform: translate(-3px, 1px) rotate(0deg); }
        70% { transform: translate(3px, 1px) rotate(-1deg); }
        80% { transform: translate(-1px, -1px) rotate(1deg); }
        90% { transform: translate(1px, 2px) rotate(0deg); }
        100% { transform: translate(1px, -2px) rotate(-1deg); }
      }

}
