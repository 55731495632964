@import "../globals.scss";


.WhatHappening{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative;

    .darkmode{
        background-color: #404040 !important;
        transition: all 0.5s;
    }

    .WhatHappeningMain{
        width: 90%;
        max-width: 1200px;
        // min-height:800px;
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        transition: all 0.5s;
        padding: 40px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        @media screen and (max-width: 700px) {
         padding: 20px 15px;
         width: 85%;
        }
    }

    .WhatHappening_titlebar{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @media screen and (max-width: 700px) {
            flex-direction: column;

           }
    }

    .WhatHappening_title{
        width: 50%;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 30px;
        font-weight: 600;
        @media screen and (max-width: 700px) {
            width: 100%;
            padding: 5px 10px;
        }
        
    }

    .WhatHappening_titleicon{
        display: flex;
        width: 50%;
        justify-content: end;
        @media screen and (max-width: 700px) {
            width: 100%;
            margin-top: 15px;
            justify-content: left;
            padding: 0 10px;

        }
    }

    .WhatHappening_titleiconmainDiv{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
    }

    .WhatHappeningIcon{
        margin: 0px 5px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 22px;
        color: #858585;
        font-weight: 600;
    }

    .WhatHappening_imgSec{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .WhatHappeningImg{
        width: 350px;
        height: 350px;
        margin: 10px;
        border-radius: 10px;
        object-fit: contain;
        cursor: pointer;

        @media screen and (max-width: 700px) {
            width: 90%;

           }
    }

    .instagramImg{
        height: 1000px !important;
        overflow-y: visible !important;
        background-color: #404040;
        border-radius: 10px;
        margin-top: 20px;
        @media screen and (max-width: 1200px) {
            height: 900px !important;
           }
        @media screen and (max-width: 1100px) {
            height: 840px !important;
           }
           @media screen and (max-width: 900px) {
            height: 710px !important;
           }
           @media screen and (max-width: 800px) {
            height:650px !important;
           }
           @media screen and (max-width: 700px) {
            height:550px !important;
           }
           @media screen and (max-width: 600px) {
            height:500px !important;
           }
           @media screen and (max-width: 500px) {
            height:400px !important;
           }
    }

    .LinktreeBtnDiv{
        display: flex;
        justify-content: center;
        align-items: centers;
        margin-top: 10px;
    }

    .LinktreeBtn{
        padding: 10px 15px;
        border-radius: 50px;
        background-color: #858585;
        font-size: 20px;
        font-weight: 700;
        color: white;
        cursor: pointer;
    }
}