@import "../globals.scss";

.InfoCard {
    display: flex;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 40px;
    height: 100%;
    justify-content: center;
    position: relative;

    .infoCard {
        width: 100%;
        max-width: 1280px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        border-radius: 20px;
    }

    .infoCardSection {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 340px;
        padding: 25px;
        background-color: #404040;
        border-radius: 10px;
        height: 220px;
        margin: 12px;
        background: linear-gradient(303deg, rgb(172 235 255) 17.72%, rgb(232 251 255) 100.62%);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        @media screen and (max-width: 700px) {
            padding: 15px;
           }
    }

    .new_bg_color{
        background: linear-gradient(275deg, rgb(226 190 190) 17.72%, rgb(255 255 255) 100.62%);
    }

    .infoCardTitle {
        font-size: 30px;
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 10px;
        font-weight: 700;
        text-align: center;
        color: #000000;
        // margin-top: 16%;

    }



    .infoCardText {
        font-size: 18px;
        text-align: center;
        align-items: center;
        color: #000000;
    }

    .infoCardButton {
        display: inline-block;
        background-color: #000000;
        color: #ffffff;
        padding: 10px 40px;
        text-decoration: none;
        font-size: 16px;
        border-radius: 22px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #5f6f52;
        }
    }
}