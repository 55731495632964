@import "../globals.scss";

.Shows{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative;

    .darkmode{
        background-color: #404040 !important;
        transition: all 0.5s;
    }


    .ShowsMain{
        width: 90%;
        max-width: 1200px;
        min-height:400px;
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        transition: all 0.5s;
        padding: 40px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        @media screen and (max-width: 700px) {
            padding: 20px 15px;
            width: 85%;
           }
    }

    .ShowsTitle{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 36px;
        font-weight: 600;
        color: #C995EB;
}

.CardShows{
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 700px) {
        width: 100%;
       }
}

.CardShows_img{
    width: 280px;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;

}

.CardShowsCarousel{
    margin-top: 14px;
}


.linkinmagazineSearch{
    text-decoration: none;
    width: 100%;
    display: flex;
    height: 100%;
}



}