@import "../globals.scss";

.sports-HomeLive {
    display: flex;
    width: 100%;
    margin-top: 30px;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;

    .darkmode {
        background-color: #404040 !important;
        transition: all 0.5s;
    }
    .whitemode{
        color: #404040 !important;
    }
    .sports-HomeLiveCard {
        max-width: 1280px;
        width: 100%;
        display: flex;
        gap: 24px;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        @media screen and (max-width: 700px) {
            flex-direction: column;
        }
    }

    .sports-cardSection {
        display: flex;
        flex-direction: column;
        flex: 0 1 0px;
        padding: 25px;
        border-radius: 10px;
        height: 300px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        
        @media screen and (max-width: 700px) {
            padding: 20px 10px;
            width: 85%;        }
    }
    .sports-cardSection-livestream{
        display: flex;
        flex-direction: column;
        flex: 2 1 0px; 
        
        padding: 25px;
        border-radius: 10px;
        height: 300px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        
        @media screen and (max-width: 700px) {
            padding: 20px 10px;
            width: 85%;        }
    }

    .sports-liveRadioTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .sports-liveRadioTitleTxt {
        font-size: 30px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 700;
        color: cyan;

    }

    .sports-instagramhandle {
        font-size: 18px;
        color: #888;
        margin-left: 10px;
    }

    .sports-radioPlaylistNameactive {
        // border: 3px solid #00B6E0;
        // background: #124466;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sports-AnnouncementsImgData {
        width: 100%;
        height: 260px;
        cursor: pointer;
        border-radius: 10px;
    }
    .sports-newVideoSection {
        font-size: 30px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 700;
        // margin-bottom: 15px;
        color: cyan;
    }
    .sports-brodcastdetails{
        font-size: 18px;
        color: #888;
        margin-left: 10px;
    }

    .sports-videoIframe {
        width: calc(100%); 
        margin: 0;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}
