@import "../globals.scss";

.bottom-section {
    // width: 90%; // Match the width of .show-slider-main
    // max-width: 1200px; // Same as .show-slider-main

    width: 100%;
    max-width: 1280px;
    flex-wrap: wrap;
    
    display: flex;
    flex-direction: row;
    justify-content: center; // Use space-around or centerto add separation between cards
    align-items: stretch;
    margin: 30px auto;
    padding: 20px 40px; // Adjust padding to match .show-slider-main
    border-radius: 20px;
    
    @media screen and (max-width: 700px) {
      flex-direction: column;
      justify-content: stretch;
      padding: 20px 15px; // Match the mobile padding of .show-slider-main
    }

  .darkmode {
    background-color: #404040 !important;
    transition: all 0.5s;
  }
  .whitemode{
    h2{
      color: #fff !important;
    }
    
  }

  .service-request-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    color: #fff;
    align-items: center;
    flex: 0 0 calc(33.333% - 20px); // Each card takes up an equal amount of space, minus margin
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    background: inherit;
    margin: 0 10px; // This margin creates the separation between cards
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    // background: linear-gradient(135deg, #3498db 0%, #88888882 100%);    // Adjust margin for mobile view to match .show-slider-main
    @media screen and (max-width: 700px) {
      width: 100%; // Cards should stack and take full width on mobile
      margin: 10px 0; // Slight adjustment for vertical stacking
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 10px;
    }

    .service-tag{
      font-family: Arial, Helvetica, sans-serif;
      font-size: 36px;
      font-weight: 700;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 80%;
      color: white !important;
      @media screen and (max-width: 700px) {
        width: 100%; // Cards should stack and take full width on mobile
      }
    }

    

    button {
      padding: 10px 40px;
      border-radius: 22px;
      border: none;
      background-color: #000000;
      color: #ffffff;
      text-decoration: none;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #212222;
      }

    }
    
  }

  .bottom-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    color: #fff;
    align-items: center;
    flex: 0 0 calc(33.333% - 20px); // Each card takes up an equal amount of space, minus margin
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    background: inherit;
    margin: 0 10px; // This margin creates the separation between cards
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    // Adjust margin for mobile view to match .show-slider-main
    @media screen and (max-width: 700px) {
      width: 100%; // Cards should stack and take full width on mobile
      margin: 10px 0; // Slight adjustment for vertical stacking
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 10px;
    }
    
    button {
      padding: 10px 40px;
      // border-radius: 5px;
      border-radius: 22px;
      border: none;
      // background-color: #3498db;
      background-color: #000000;
      color: #ffffff;
      text-decoration: none;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #212222;
      }


    }
  }

  .music-feature-card {
    background: linear-gradient(135deg, #1abc9c 0%, #84e4d183 100%);
  }
  
  .questions-card {
    background: linear-gradient(135deg, #f39c12 0%, #efbd9b7a 100%);
  }
  
  .discord-card {
    background: linear-gradient(135deg, #3498db 0%, #94ccf182 100%);
  }


  @media screen and (max-width: 700px) {
    flex-direction: column;
    .bottom-card, .service-request-card {
      width: 80%;
      margin-bottom: 20px;
      margin: 20px; // Adjust margin for mobile
    }
  }
}
