.card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    &.single-column {
      margin: 16px auto;
      width: 100%; // Full width for single column layout
    }
  
    &.double-column {
      margin: 16px;
      width: calc(50% - 32px); // Adjust width for two columns, assuming 16px gutter
    }
  
    .card-title {
      font-size: 1.25rem;
      color: #333;
      padding: 16px;
      margin: 0;
    }
  
    .card-username {
      font-size: 0.85rem;
      color: #666;
      padding: 0 16px;
    }
  
    .card-image {
      width: 100%;
      display: block;
    }
  
    // Responsive adjustments for smaller screens
    @media (max-width: 768px) {
      &.double-column {
        width: 100%; // Stack columns on smaller screens
      }
    }
  }
  