@import "../globals.scss";

.MagazineCards{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative;
    
    .darkmode{
        transition: all 0.5s;

    }
    .navLinkDesign{
        text-decoration: none !important;
    }


    .MagazineCardsMain{
        width: 100%;
        max-width: 1200px;
        min-height:500px;
        border-radius: 20px;
        transition: all 0.5s;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 700px) {
         padding: 20px 15px;
        }
    }
    .LatestArticlesTitle{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 30px;
        font-weight: 600;
    }

    .MagazinesCards{
        min-height: 430px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }



    .MagazineArticlesCardslightmode{
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

  

    .MagazineCardsShowBtn{
        display: flex;
        justify-content: center;
        align-items: center;

        .btnShowmore{
            background-color: #515050;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18px;
            padding: 10px 20px;
            border-radius: 20px;
            cursor: pointer;
            color: white;

        }

        
    }


}

.MagazineArticlesCards{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 504px;
    background-color: #515050;
    margin: 20px 15px;
    border-radius: 10px;
    padding: 15px;
    @media screen and (max-width: 700px) {
       margin: 10px 0px ;
       }
    .MagazineArticlesCardsimage{
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
    }

    .MagazineArticlesCardstype{
        margin-top: 10px;
        display: flex;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height:24px;
        overflow: hidden;
        text-overflow: ellipsis; 
        text-align: left;
        font-size: 18px;
        font-weight: 200;
        color: var(--text-color);
        text-transform: uppercase;
    }

    .MagazineArticlesCardstopic{
        margin-top: 10px;
        display: flex;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        height:120px;
        overflow: hidden;
        text-overflow: ellipsis; 
        text-align: left;
        font-size: 26px;
        font-weight: 600;
        color: $Blue-color;
        word-wrap: break-word;
    }

    .MagazineArticlesCardsAuthor{
        font-size: 18px;
        margin-top: 10px;
        height: 36px;
        font-weight: 200;
        overflow: hidden;
        display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
        color: var(--text-color);
    }

    .MagazineArticlesCardsText{
        font-size: 18px;
        margin-top: 10px;
        height: 84px;
        font-weight: 200;
        overflow: hidden;
        display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
        color: var(--text-color);
    }
    
}


.LatestArticlesMODAL{
    height: 500px;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

