@import "../globals.scss";

.RecentArticle{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative;

    .recentArticleMain {
        width: 90%;
        max-width: 1050px;
        font-family: Arial, Helvetica, sans-serif;
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        border-radius: 20px;
        padding: 12px 0px;
    
        @media screen and (max-width: 700px) {
            flex-direction: column;
            padding: 20px 15px;
        }
    
        .ImageSection {
            width: 58%;
            border-radius: 20px;
    
            @media screen and (max-width: 700px) {
                width: 100%;
            }
        }
    
        .RecentArticleImage {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    
        .RecentArticleTopic {
            width: 36%;
    
            @media screen and (max-width: 700px) {
                width: 100%;
                margin-top: 20px;
            }
    
            .RecentArticleType{
                font-size: 16px;
                color: $Blue-color;
                text-transform: uppercase;
            }
    
            .RecentArticleTitle {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-align: left;
                text-decoration: none;
                font-size: 36px;
                color: var(--text-color) ;

                margin-top: 20px;
            }
    
            .RecentArticleDate {
                font-size: 16px;
                margin-top: 28px;
            }
    
            .RecentArticleAuthor {
                font-size: 16px;
                text-decoration: none;
                margin-top: 4px;
                color: var(--text-color) ;
            }
    
            .RecentArticleText {
                font-size: 16px;
                margin-top: 24px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
    
    
            h2 {
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 10px;
            }
    
            p {
                font-size: 16px;
                font-weight: 300;
                color: var(--text-color);
            }
        }
    }
}

.darkmodeRecentArticle {
    transition: all 0.5s;
    background-color: #515050;
}

.lightmodeRecentArticle{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.RecentArticleLink{
    text-decoration: none !important;
}