@import "../globals.scss";

.Herobanner{
    position: relative;
    max-height: 900px !important;
    background-color: var(--background-color);

    .HerobannerCom{
        display: flex;
        justify-content: center;
        align-items: center;
    }

   
    .HerobannerData{
        top: 0;
        bottom: 0;
        flex-direction: column;
        margin: auto 0%;
        width: 35%;
        backdrop-filter: blur(50px);
        justify-content: center;
        display: flex;
        padding: 10px;

        @media screen and (max-width: 700px) {
            position: absolute;
            margin: auto 4%;
            max-height: 50%;
            border-radius: 20px;
            opacity: 0.7;
            padding: 30px;
            width: auto;
            color: black;
            background-color: #fff;
         }

         @media screen and (max-width: 400px) {
            margin: auto auto;
            width: 97%;
         }

    }


    .aboutData{
        top: 0;
        bottom: 0;
        flex-direction: column;
        margin: auto 0%;
        width: 35%;
        backdrop-filter: blur(50px);
        justify-content: center;
        display: flex;
        padding: 10px;

        
        .btnShowmore{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px auto;
            background-color: $Blue-color;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18px;
            padding: 10px 20px;
            border-radius: 20px;
            cursor: pointer;

            a{
                text-decoration: none;
                color: var(--text-color);
            }
        }

        @media screen and (max-width: 700px) {
            position: absolute;
            margin: auto 4%;
            height: 50%;
            border-radius: 20px;
            opacity: 0.7;
            padding: 8px;
            width: auto;
            color: black;
            background-color: #fff;
         }

         @media screen and (max-width: 400px) {
            margin: auto auto;
            width: 97%;
         }

    }

    .btnShowmore{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
        background-color: $Blue-color;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        padding: 10px 20px;
        border-radius: 20px;
        cursor: pointer;

        a{
            text-decoration: none;
            color: var(--text-color);
        }
    }

    .HerobannerHeading{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 50px;
        font-weight: 600;
        display: flex;
        text-align: left;
        padding: 10px 30px;
        @media screen and (max-width: 500px) {
            font-size: 9.5vw;
             padding: 10px 0px;
        text-align: justify;


             }

    }

    .HerobannerText{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        display: flex;
        font-weight: 500;
        text-align: left;
        padding: 0px 30px;
        @media screen and (max-width: 500px) {
        font-size: 4.5vw;
        padding: 10px 0px;
        text-align: justify;
         }
    }
    .bigText{
        font-weight: 600;
        font-size: 56px !important;
    }

    .aboutText{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        display: flex;
        text-align: left;
        padding: 0px 20px;
        margin-top: 10px;
        text-align: justify;

    }
    .imageTag{
        min-height: 600px ;
        object-fit: cover;
        height: 750px;
        width: 65% !important;

        @media screen and (max-width: 700px) {
            width: 100% !important;
         }

    }

    .image-container-magazine {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65% !important;
        min-height: 600px;
        height: 750px;
        overflow: hidden;
      
        @media screen and (max-width: 700px) {
            width: 100% !important;
        }
    }
    
    .imageTagMagazine {
        height: 100%; 
        width: auto; 
        object-fit: contain;
    }   

    .HerobannerBtn{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 26px;
        display: flex;
        background-color: goldenrod;
        border-radius: 50px;
        margin-top: 30px;
        min-height: 50px;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
        padding: 5px 25px;
        width: fit-content;

        @media screen and (max-width: 700px) {
           width: 100%;
           margin-left: 0;
        }


    }
}