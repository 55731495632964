@import "../globals.scss";



.MagazineTypes{
width: 100%;

.navLinkDesign{
    text-decoration: none !important;
}


.searchBar{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 180px;
    width: 100%;

    .inputSearchbar{
        width: 60%;
        height: 50px;
        font-size: 24px;
        border-radius: 50px;
        padding-left: 20px;
    }
}

.btnSearchField{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
    flex-wrap: wrap;



}

.linkinmagazineSearch{
    text-decoration: none;
    width: 100%;
    height: 100%;
}

.btnSearchField_tag{
    margin: 10px;
    font-size: 20px;
    padding: 4px 20px;
    cursor: pointer;
    border-radius: 0px;
    transition: all 1s;

    @media screen and (max-width: 700px) {
        font-size: 16px;
    padding: 4px 10px;
    margin: 2px;



       }


    &:hover{
    background-color: #4CC7F3;
    color:white;
    border-radius: 50px;
    transition: all 1s;


    }
    
}

.btnSearchField_tag_active{
    background-color: #4CC7F3;
    color:white;
    border-radius: 50px;


}


.cardSectionMG {
  width: 340px;
  height: 450px;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
 
  .textDataCard{
    padding-top: 10px;
    height: 125px;
    overflow: hidden;
  }
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
    transition: opacity .2s ease-out;
  }

  .headTitle {
    position: absolute;
    inset: auto auto 30px 30px;
    color:var(--text-color);
    outline: none;
    margin: 10px 0px;
    transition: inset .3s .3s ease-out;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: normal;
    font-size: 22px;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .textDataCard {
    position: absolute;
    opacity: 0;
    color: var(--text-color);
    outline: none;
    max-width: 80%;
    transition: opacity .3s ease-out;
  }
  
  .textDataCard {
    inset: auto auto 80px 30px;
  }
  

  &:hover .headTitle {
    inset: auto auto 220px 30px;
    transition: inset .3s ease-out;
  }
  
  &:hover .textDataCard{
    opacity: 1;
    transition: opacity .5s .1s ease-in;
  }
  
  &:hover img {
    transition: opacity .3s ease-in;
    opacity: 0.8;
  }

}

.material-symbols-outlined {
  vertical-align: middle;
}


.megazineSection{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap;
}}
