@import "../globals.scss";

.navBarMain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    flex-direction: column;
    backdrop-filter: blur(5px);
    z-index: 5 !important;
    top: 0;

    .navBarMain_card {
        display: flex;
        width: 100%;
        position: relative;
        max-width: 1330px;
        justify-content: center;

        @media screen and (max-width: 1025px) {
            border-bottom: 1px solid $MenuFontcolor;
            height: 60px;
            margin-top: 0;
        }


    }

    .socialMediaBtns {
        position: absolute;
        right: 0;
        height: 100%;
        max-height: 70px;
        padding-right: 2%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1025px) {
            display: none;
        }
    }
    .volume-control {

        position: absolute;
        top: 60px;
        opacity: 0;
        display: none;
        visibility: hidden;
        z-index: 1001;
        background-color: #404040;
        border-radius: 5px;
        padding: 5px;
    
        &.show {
            visibility: visible;
            opacity: 1;
            display: flex;
            width: auto;
        }

        input[type="range"] {
            -webkit-appearance: none;
            background: #ffffff47;
            width: 100px;
            border-radius: 40px;
    
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: white;
                cursor: pointer;
            }
    
            &::-moz-range-thumb {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: white;
                cursor: pointer;
            }
        }
    


    }
    .iconSocialMedia {
        margin-right: 15px;
        cursor: pointer;
        max-height: 40px;
    }

    .navBarMainLOGO {
        position: absolute;
        height: 100%;
        max-height: 70px;
        display: flex;
        left: 0;
        padding-left: 2%;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1360px) {
        }

        @media screen and (max-width: 1025px) {
            position: absolute;
            left: 10px;
        }

        img {
           
           
            height: 80%;
            mix-blend-mode: difference ;

            @media screen and (max-width: 1360px) {
                width: 100%;
            }

            @media screen and (max-width: 1025px) {
                width: 100%;
                height: 100%;
                max-height: 48px;
                
            }
        }


    }

    .navBarBtn {
        width: 80%;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: 100%;
        max-width: 800px;

        @media screen and (max-width: 1360px) {
            width: 50%;
            min-width: 450px;
            max-width: 100%;
        }
    }

    .menuBar {
        padding: 6px;
        flex-shrink: 0;
        height: 50px;
        border-radius: 50px;
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: center;
        align-items: center;
        background: $white;
        box-shadow: 2px 2px 8px -2px rgba(0, 0, 0, 0.25);
        border: none;
        transition: all 1s;

       &-dark {
            background-color: rgb(8, 8, 17);
            border: solid 1px rgb(8, 127, 170);
            box-shadow: 2px 2px 8px -2px rgba(255, 255, 255, 0.25);

        }

        &-light {
            background-color: #fff;
            color: $FontblackColor !important;
        }

        @media screen and (max-width: 1360px) {}

        @media screen and (max-width: 1025px) {
            display: none;
        }

        .menuBtn {
            width: 100%;
            height: 100%;
            margin: 0 4px;
            padding: 0px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            font-family: 'Inria Sans', sans-serif;
            line-height: normal;
            font-weight: 600 !important;
            font-size: 19px;
            font-style: normal;

            color: $MenuFontcolor;
            cursor: pointer;
            border-radius: 50px;
            transition: all 0.5s;

         

            @media screen and (max-width: 1360px) {
                font-size: 17px;

            }
        }

        .menuBtn:hover {
            background-color: $Blue-color ;
            color: $white;
        }

     

        .redioBtn {
            width: 20%;
            margin-left: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 155px;
            height: 42px;
            flex-shrink: 0;
            border-radius: 10px;
            background: #DDD;
            font-size: 19px;
            font-style: normal;
            font-weight: 800;
            font-family: 'Inria Sans', sans-serif;
            right: 10px;
            color: $MenuFontcolor;
            padding: 0 10px;
            cursor: pointer;

            @media screen and (max-width: 1360px) {
                width: 19%;
                font-size: 15px;

            }

            img {
                mix-blend-mode: darken;
                margin-right: 10px;
            }

        }


    }



    ::placeholder {
        color: #A6A6A6;
    }

    ::-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #A6A6A6;
    }

    ::-ms-input-placeholder {
        color: #A6A6A6;
    }

    .searchBar:focus-visible {
        border: 2px solid #A5A5A5;

    }


    .mobileMenuBarBtn {
        position: absolute;
        right: 10px;
        top: 10px;
        height: 30px;
        cursor: pointer;

        @media screen and (min-width: 1025px) {
            display: none;
        }

        img {
            height: 100%;
        }
    }


    .menuBarMobileFalse {
        height: 0px;
        background-color: white;
        flex-direction: column;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border-radius: 10px;
        z-index: 8;
        position: absolute;
        width: 95%;
        max-width: 400px;
        transform: translate(0%, 25%);
        margin-top: 60px;
        display: flex;
        overflow: hidden;
        transition: all 0.5s;
        .menuBtn {
            font-size: 20px;
            color: #A6A6A6;
            font-family: 'Inria Sans', sans-serif;
            border-bottom: 1px solid;
            padding-bottom: 10px;
            width: 100%;
            font-size: 25px;
            height: 70px;
            font-style: normal;
            font-weight: 600 !important;
            cursor: pointer;
        transition: all 1s;


        }

    }

    .menuBarMobile {
        position: absolute;
        width: 95%;
        transform: translate(0%, 15%);
        display: flex;
        height: 500px;
        max-width: 400px;
        flex-direction: column;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border-radius: 10px;
        padding: 10px;
        z-index: 8;
        transition: all 0.2s;
        background-color: #404040;
        color: white;

        .menuBtn {
            font-size: 20px;
            color: white;
            font-family: Arial, Helvetica, sans-serif;
            border-bottom: 1px solid;
            padding-bottom: 10px;
            width: 100%;
            height: 60px;
            font-size: 25px;
            padding-top: 10px;
            font-style: normal;
            font-weight: 700 !important;
            cursor: pointer;
            transition: all 1s;


        }




        @media screen and (min-width: 1025px) {
            display: none;
        }

       
    }

}


.titleBarRadio{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    min-height: 55px;
    background-color: $FontblackColor;
    margin-bottom: 14px;
    border-bottom: solid 1px rgb(8, 127, 170);
    top: 0;

    .titleBarRadioleft{
        display: flex;
        flex-direction: row;
        width: 90%;
        align-items: center;
        min-height: 55px;
        background-color: $FontblackColor;
        flex-wrap: wrap;
        top: 0;
    }

    .titleBarRadioright{
        width: 10%;
        min-width: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 55px;
        background-color: $FontblackColor;
        flex-wrap: wrap;
        top: 0;
      
}
    .radioSelectionBtn{
        position: relative;
        margin-right: 10px;
      
    }

    .selectionTagName{
        width: 180px;
        height: 35px;
        color: white !important;
        font-family: Arial, Helvetica, sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px white;
        border-radius: 50px;
        margin-top: 3px;
        margin-left: 5px;
        font-size: 16px;
        @media only screen and (max-width: 800px) {
           width: 115px;
           justify-content: left;
           padding-left: 10px;
          font-size: 14px;

            }

    }
    .downarowCss{
        position: absolute;
        height: 10px;
        right: 10px;
        rotate: 0deg;
        transition: all 0.5s;


    }

    .uparowCss{
        position: absolute;
        height: 10px;
        right: 10px;
        rotate: 180deg;
        transition: all 0.5s;

    }

    .selectionTagMenuON{
        position: absolute;
        border-radius: 10px;
        top: 42px;
        width: 175px;
        background-color: #DDD;
        height: 155px;
        margin-left: 10px;

        background-color: $FontblackColor;
        z-index: 10;
        height: 155px;
        @media only screen and (max-width: 800px) {
            width: 115px;
             }
    }

    .selectionTagMenuOFF{
        position: absolute;
        top: 42px;
        width: 175px;
        background-color: #DDD;
        background-color: $FontblackColor;
        z-index: 10;
        margin-left: 10px;

        height: 0;
        overflow: hidden;
        transition: all 1s;
        @media only screen and (max-width: 800px) {
            width: 115px;
             }

    }



    .selectionTagMenuItem{
        border-top: solid 1px rgb(8, 127, 170);
        height: 55px;
        display: flex;
        color: white !important;
        align-items: center;
        padding: 10px;
        cursor: pointer;

    }

    .donateANContactBtn{
        padding: 5px 15px;
        border: solid 1px white !important;
        margin: 10.25px 8px;
        color: white !important;
        border-radius: 50px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        a{
            color: #fff;
            text-decoration: none;
        }
        @media only screen and (max-width: 680px) {
            display: none;
            }
    }

    .sunBtn{
    position: absolute;
    height: 25px;
    top: 7px;
     

    }

    .rightSun{
        right: 8px;
    }

    .leftMoon{
        left: 8px;
    }

    .roundBtnLeft{
        width: 33px;
        height: 33px;
        background-color: #A6A6A6;
        border-radius: 50px;
        top: 3px;
        left: 5px;
        position: absolute;
        z-index: 6;
        transition: all 1s;

    }

    .roundBtnRight{
        width: 33px;
        height: 33px;
        background-color: #A6A6A6;
        border-radius: 50px;
        top: 3px;
        left: 47px;
        position: absolute;
        z-index: 6;
        transition: all 1s;
    }

    .DarkModeBtn{
    position: absolute;
    right: 20px;
    height: 35px;
    top: 8px;
    padding: 2px;
    width: 80px;
    border: solid 2px white;
    cursor: pointer;
    border-radius: 50px;
    @media only screen and (max-width: 400px) {
        right: 5px;

        }

}


    .searchBtn{
    width: 20px;}

    .radioNameandStart{
        display: flex;
        align-items: center;
       
        @media only screen and (max-width: 800px) {
            width: 150px;
            }
        @media only screen and (max-width: 400px) {
                width: 110px;
                }

       
        min-height: 55px;
        color: white;
        
        .titleName{
            font-size: 18px;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .artistName{
            font-size: 14px;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .playButton{
            height: 40px;
            width: 40px;
            cursor: pointer;
            @media only screen and (max-width: 400px) {
                height: 30px;
            width: 30px;
                }

        }
        .content {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis; // Add an ellipsis if the text is too long
        }
        
        .marquee {
            overflow: hidden;
            white-space: nowrap;
        
            .marquee-content {
                display: inline-block;
                padding-left: 100%;
                animation: marquee 10s linear infinite;
            }
        
            @keyframes marquee {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(-100%);
                }
            }
        }
        
        .flexCenter {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .grid-container {
            display: grid;
            grid-template-columns: repeat(4, auto); // Adjust this depending on the number of items
            grid-gap: 10px; // Add some space between items
            align-items: center;
            @media only screen and (max-width: 400px) {
                grid-gap: 4px; // Add some space between items

                }

        }
        .grid-itemBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 5px;
            width: 200px;
            @media only screen and (max-width: 800px) {
                display: none;
            }
        }
        .album-art {
            width: 40px;
            border-radius: 50%; // Adjust the width of the album art as needed
            @media only screen and (max-width: 400px) {
                height: 30px;
                width: 30px;
                }
        }
    }
   
}

.navLinkCSSDesign{
    display: flex;
    height: 100%;
    width: 100%;
    text-decoration: none;
}


    

.activeClassNav{
    background-color: $Blue-color ;
    color: $white;
}
.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid rgb(76, 200, 244);
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: 10px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }