@import "../globals.scss";

#radio {
    max-width: 1024px;
    margin: 0 auto; // Centers the section

    h1 {
        color: $primary-bl-100; // Example heading color, adjust as needed
        text-align: center;
    }

    .intro,
    .section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .col {
            flex: 1;

            // The following style is for text columns
            &.text {
                padding: 0 20px; // Add padding as needed
                text-align: left;
            }
        }

        img {
            width: 20%; // Adjust based on your layout requirements
        }

        .year {
            font-weight: bold;
            font-size: 1.2em; // Adjust font size as needed
        }

        // When you want to reverse the order of flex items
        &.flex-reverse {
            flex-direction: row-reverse;
        }
    }

    .accordion-item {
        margin-bottom: 10px; // Spacing between accordion items

        .accordion-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            background-color: transparent;
            color: var(--text-color);
            border-bottom: 4px solid darkgrey;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                border-bottom: 4px solid darken(#4cc8f4, 5%);
            }
            .year {
                // Styles for the year go here
            }
            &.open{
                border-bottom: 4px solid darken(#4cc8f4, 5%) !important;
            }
            .accordion-icon {

                font-size: large;
            }
        }

        .accordion-content {
            padding: 10px;
            // border: 1px solid #4cc8f4; // Example color, adjust as needed
            border-top: none; // Hide the top border to blend with the title        
            display: flex; // Enables flexbox layout
            flex-wrap: wrap; // Allows items to wrap to the next line as needed
            gap: 10px; // Adjust the space between grid items

            img {
                max-width: 300px;
                max-height: 200px;
                width: auto; // Scale width automatically to maintain aspect ratio
                height: auto; // Scale height automatically to maintain aspect ratio
                cursor: zoom-in; /* You can use other cursor styles like 'pointer' if you prefer */
                object-fit: cover; // Ensures the image covers the area, may be cropped to maintain aspect ratio
                border-radius: 4px; // Optional: if you want rounded corners for images
                box-shadow: 0 2px 5px rgba(0,0,0,0.2); // Optional: adds shadow for depth
            }
            &.open {
              display: block; // Show content when open
            }
        }
    }
    .image-accordion {
        .accordion-content {
          display: flex;
          flex-direction: column;
      
          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
      
            p {
              flex: 1;
            }
      
            img {
              flex: 2;
              max-width: 30%; // Adjust this as needed
            }
          }
        }
      }
      
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      
        img {
          max-width: 90%;
          max-height: 90%;
        }
      }

    // Responsive adjustments if needed
    @media (max-width: 768px) {

        #radio .intro,
        #radio .section {
            flex-direction: column;

            img {
                width: 100%; // Full width on smaller screens
                order: -1; // Image above text on smaller screens
            }

            .col {
                // Ensure text columns stack full-width on smaller screens
                width: 100%;
                order: 2;
            }

            .year {
                order: 1; // Ensure year stays between the image and text
            }
        }
    }

}