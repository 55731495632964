@import "../globals.scss";


.Devteam{
    margin-top: 60px;
}

.DevTeamtext{
    font-size: 40px;
    font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  justify-content: center;
  text-align: center;
}

.About{

    width: 100%;

    .AboutStaffMenu{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 40px;
        position: relative;}

    .AboutStaffMenuNav{
        width: 90%;
        height: 40px;
        max-width: 710px;
        border-radius: 50px;
        transition: all 0.5s;
        padding: 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        border: 1px solid hsl(196, 88%, 63%);
        flex-direction: row;
        @media screen and (max-width: 720px) {
           height: 30px;
            }
      

        .AboutStaffMenuitem{
            margin: 0px 10px;
            cursor: pointer;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18px;
            font-weight: 500;
            padding:  8px 15px;
            border-radius: 0px;
            transition: all 1s;
            @media screen and (max-width: 720px) {
            font-size: 2.4vw;
            padding:  4px 10px;
            margin: 0px 2px;
            }

            
        }

        .active{
            background-color: #4CC8F4;
            color: black;
            border-radius: 50px;
            transition: all 1s;
        }

        .AboutStaffMenuitem:hover{
            background-color: #4CC8F4;
            color: black;
            border-radius: 50px;
            transition: all 1s;

        }
    }

    .AboutStaffMenucard{
        width: 100%;
        width: 90%;
        max-width: 1200px;
        display: flex;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }





}





.aboutTeamSection{
    display: flex;
    width: 100%;
    margin: 30px auto;
    justify-content: center;
    color: black;

    .darkmode {
        background-color: #404040 !important;
        transition: all 0.5s;
      }

    .aboutTeamSection_main{
        width: 90%;
        max-width: 1200px;
        min-height: 200px;
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        transition: all 0.5s;
        padding: 20px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    
        @media screen and (max-width: 700px) {
          padding: 20px 15px;
          width: 85%;
        }

        .aboutTeamSection_title{
            font-size: 30px;
            font-family: "Anta", sans-serif;
          font-weight: 400;
          font-style: normal;
          display: flex;
          text-align: center;
          margin-bottom: 20px;
          color: var(--text-color);
          @media screen and (max-width: 550px) {
            font-size: 6vw;
              }
        }

        .navLinkData{
            width: 100%;
        }


        .aboutTeamSectionData{
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: row;

            @media screen and (max-width: 850px) {
                flex-direction: column;
              }

            img{
                width: 70%;
                border-radius: 10px;
                max-height: 500px;
                object-fit: cover;
                @media screen and (max-width: 850px) {
                width: 100%;

                  }
            }

            .textAboutTeamPage{
                text-align:justify;
          color: var(--text-color);

            }

            .techteam_aboutText{
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 30%;
                flex-direction: column;
                @media screen and (max-width: 850px) {
                    width: 100%;
                    margin: 10px 0;
                    padding: 0;
    
                      }
            }

            .techteam_aboutTextbtn{
                padding: 7px 20px;
                border: solid 1px #4CC8F4;
                border-radius: 10px;
                margin-top: 10px;
                width: 90%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                color: #4CC8F4;
                cursor: pointer;

            }
        }

    }

}