@import "../globals.scss";

.MagazineOpen {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .MagazineOpen_card {
        width: 100%;
        border-radius: 27px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .MagazineOpen_cardsrc {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            @media screen and (max-width: 700px) {
                margin: 10px;

            }
        }

        .titleImage {
            width: 100%;
            max-height: 650px;
            object-fit: cover;
            @media screen and (max-width: 700px) {
               height: 500px;

            }
        }

        .MagazineOpen_text {
            width: 80%;
            margin-top: -100px ;
            background-color: white;
            padding: 20px; 
            border: 1px solid gray; 
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 15px;          


            @media screen and (max-width: 700px) {
                margin-top: -100px ;


            }
        }

        .MagazineOpen_type {
            color: $accent-300;
            font-size: 26px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-style: normal;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            line-height: normal;

            @media screen and (max-width: 700px) {
                font-size: 30px;
            }

        }

        .MagazineOpen_mgname{
            font-size: 46px;
            display: flex;
            justify-content: center;
            color: #232323;
            margin-top: 10px;
            width: 100%;
            font-weight: 700;
            justify-content: center;
            align-items: center;
            width: 100%;
            text-align: center;
            @media screen and (max-width: 700px) {
                font-size: 30px;
            }

        }

        .MagazineOpen_Date {
            color: $MenuFontcolor;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .MagazineOpen_titleData{
            color: $MenuFontcolor;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 25px;
            font-style: normal;
            opacity: 70%;
            font-weight: 600;
            margin-top: 20px;
            width: 90%;
            text-align: center;
            align-items: center;
            line-height: normal;
            @media screen and (max-width: 700px) {
                width: 95%;
            }
        }

        .MagazineOpen_name {
            color: $MenuFontcolor;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 25px;
            font-style: normal;
            opacity: 70%;
            font-weight: 700;
            display: flex;
            justify-content: center;
            text-align: center;
            
            margin-top: 25px;
            line-height: normal;
            @media screen and (max-width: 700px) {
                width: 95%;
            }
        }

        .MagazineOpen_data {
            color: #232323;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            margin-top: 20px;
            width: 70%;
            line-height: normal;
            text-align: justify;
            @media screen and (max-width: 700px) {
                width: 95%;
            }
        }
        
        .MagazineIssueBtn{
            display: flex;
            justify-content: center;
            align-items: center;

            .ReadFullIssuebtn{
                background-color: #515050;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 18px;
                padding: 10px 20px;
                border-radius: 20px;
                cursor: pointer;
                color: white;
                text-decoration: none;

            }

            
        }
    }

}