.tooltip-container {
    position: absolute; 
    top: 35%; 
    left: 20%; 
    transform: translateX(-50%); 
    width: 250px; 
    background-color: #000;
    color: #fff; 
    padding: 10px;
    border-radius: 6px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    display: flex;
    align-items: center;
    visibility: hidden; 
    opacity: 0;
    transition: visibility 0s, opacity 0.5s ease;
    z-index: 1000;
    
    @media (max-width: 767px) {
        width: calc(100% - 100px); 
        top: auto; 
        bottom: -5px; 
        left: 10px;
        transform: none;
      }
    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
  
  .tooltip-album-art {
    width: 80px; 
    height: 80px; 
    margin-right: 10px; 
    border-radius: 4px;
    @media (max-width: 767px) {
        width: 60px; 
        height: 60px; 
      } 
  }
  
  .tooltip-text {
    text-align: left;
    @media (max-width: 767px) {
        font-size: 0.9rem; 
      }
  }
  
  .tooltip-title {
    font-size: 1rem; 
    font-weight: bold; 
    margin: 0 0 5px 0; 
    @media (max-width: 767px) {
        font-size: 0.9rem; 
      }
  }
  
  .tooltip-artist {
    font-size: 0.875rem; 
    margin: 0; 
    @media (max-width: 767px) {
        font-size: 0.9rem; 
      }
  }
  