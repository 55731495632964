@import "../globals.scss";


.ScheduleCalendar-container{
    position: relative;
    display: flex;
    width: 100%;
    margin: 30px auto;
    justify-content: center;
    color: black;

    .ScheduleCalendarCSS {
        width: 90%;
        max-width: 1200px;
        min-height: 200px;
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        transition: all 0.5s;
        padding: 40px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    
        @media screen and (max-width: 700px) {
          padding: 20px 15px;
          width: 85%;
        }
      }
    .darkmode {
        background-color: #404040 !important;
        transition: all 0.5s;
      }
      .whitemode{
        color: #404040 !important;
      }
      
    .ScheduleCalendar-title {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 36px;
        font-weight: 600;
        color: cyan;
        margin-bottom: 20px; // Add some space between title and calendar
    }

    .ScheduleCalendarCSSMain {
        width: 96%; // Use 100% width to fill the container
        z-index: 1;
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    &-dark {
        .rs__cell {
            background-color: rgb(74, 74, 74) !important;
            // color: white !important;
            transition: all 1s;
        }

        .css-1419abu-MuiPaper-root {
            background-color: rgb(55, 54, 54) !important;
            // color: white !important;
            transition: all 1s;
        }

        .css-i4bv87-MuiSvgIcon-root {
            // color: white !important;
            transition: all 1s;
        }
    }
}