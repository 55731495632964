@import "../globals.scss";

.Advertisements{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative;

    .darkmode{
        background-color: #404040 !important;
        transition: all 0.5s;
    }

    .AdvertisementsMain{
        width: 90%;
        max-width: 1200px;
        min-height:250px;
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        transition: all 0.5s;
        padding: 40px;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        @media screen and (max-width: 700px) {
         padding: 20px 10px;
         width: 85%;
         min-height:25px;
        }
    }
    .Advertisements_horizontal{
        // width: 100%; // Ensures it takes the full width of its parent
        display: flex;
        margin-top: 25px;
        justify-content: center; // Centers the content horizontally
        align-items: center; // Centers the content vertically
        padding: 0 40px; // Aligns horizontally with the grid below
    
        @media screen and (max-width: 700px) {
            padding: 0px; // Adjusts padding for smaller screens
        }
    }
    
    .AdvertisementsHorizontalImgData{
        width: 100%; // Takes full width of its container
        height: auto; // Maintains aspect ratio
        border-radius: 10px; // Optional: if you want rounded corners
        transition: transform 0.3s ease; // Smooth transition for the transform property
    
        @media (min-width: 1024px) { /* Apply hover effect only on large screens */
          &:hover {
              transform: scale(1.03); /* Slight zoom on hover */
          }
      }
    }
    
    .MarqueeContainer{
        overflow: hidden;
        width: calc(100% - 80px); // Adjust based on the padding of AdvertisementsMain
        margin: 20px 40px; // Adjust based on your needs


    }
    .Advertisements_title{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 30px;
        font-weight: 600;
    }

    .AdvertisementsImgSec {
        display: flex;
        flex-direction: row;
        justify-content: flex-start; // Align items to the start
        align-items: center;
        gap: 20px; // Adds space between items
        animation: marquee 25s linear infinite;
        animation-play-state: running; // Ensure the animation is running by default
        position: relative;
        left: 40px; // Align with the padding of AdvertisementsMain
        right: 40px; // Align with the padding of AdvertisementsMain
        top: 0;
        bottom: 0;
        margin-top: 20px; // Adjust as needed
        margin-bottom: 20px; // Adjust as needed
        &:hover {
            animation-play-state: paused; // Pause animation on hover
        }
        @keyframes marquee {
            0% { transform: translateX(0); }
            100% { transform: translateX(-200%); } // Change this to ensure all images scroll into view
          }      
      }
      
      
      .AdvertisementsImgData {
        height: 343px; 
        object-fit: cover; // Ensures the aspect ratio is maintained, might crop images
        border-radius: 10px;
        display: block;
        transition: transform 0.3s ease; // Smooth transition for the transform property
      
        margin-right: 20px; // Adds a gap between each image, you can adjust this value
        
        &:last-of-type {
            margin-right: 0; // Removes margin for the last image
        }
        &:hover {
          transform: scale(1); // No zoom on hover
        }
      }
      
      
    .AdvertisementsLink {
        display: inline-block; 
        line-height: 0;
        cursor: pointer;
      
        &:hover {
          text-decoration: none; 
        }
      }
      
      .AdvertisementsImgDataWrapper {
        cursor: default;
      }
      .AdvertisementsImgData.canEnlarge:hover {
        cursor: zoom-in; /* You can use other cursor styles like 'pointer' if you prefer */
      }
      
    .AdvertisementsLink, .AdvertisementsImgDataWrapper {
        margin-bottom: 20px; // Adds a margin at the bottom of each vertical ad
    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      
        img {
          max-width: 90%;
          max-height: 90%;
        }
      }
      
}