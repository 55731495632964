@import "../globals.scss";

.HomeLive {
    display: flex;
    width: 100%;
    margin-top: 30px;
    height: 100%;
    justify-content: center;
    position: relative;

    .HomeLiveCard {
        width: 90%;
        max-width: 1280px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: row;


    }

    .cardSection {
        display: flex;
        flex-direction: column;
        width: 350px;
        padding: 25px;
        background-color: #404040;
        border-radius: 10px;
        height: 300px;
        margin: 12px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        @media screen and (max-width: 700px) {
            padding: 15px;
           }
    }

    .liveRadioTitle {
        display: flex;
        flex-direction: row;
    }

    .liveRadioTitleRedbtn {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        background-color: red;
        justify-content: center;
        margin-top: 8px;
    }

    .liveRadioTitleTxt {
        font-size: 30px;
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 10px;
        font-weight: 700;

    }

    .radioPlaylistNameactive {
        border: 3px solid #00B6E0;
        background: #124466;
        padding: 10px;
        border-radius: 10px;
        margin-top: 25px;
        cursor: pointer;
    }

    .radioPlaylistName {
        border: 3px solid #2C4053;
        background: #121B1F;
        padding: 10px;
        border-radius: 10px;
        margin-top: 10px;
        cursor: pointer;

    }
    .radioPlaylistName_nowplaying{
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #00B6E0;
        text-overflow: ellipsis;
    }

    .radioPlaylistName_name {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 10px;
        color: white;
        // white-space: nowrap; 
        // overflow: hidden;
        text-overflow: ellipsis;
    }

    .radioPlaylistName_txt {
        font-size: 18px;
        margin-bottom: 2px;
        color: white;
        // white-space: nowrap; 
        // overflow: hidden;
        text-overflow: ellipsis;

    }

    .radioPlaylistName_time {
        font-size: 18px;
        margin-bottom: 2px;
        color: white;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;

    }

    .upNextTitle {
        font-size: 30px;
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 10px;
        font-weight: 700;
        margin-top: 10px;

    }

    .newVideoSection {
        font-size: 30px;
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 10px;
        font-weight: 700;
    }

    .videoIframe {
        margin-top: 25px;
        border-radius: 10px;
        overflow: hidden;

    }

    .NewMagazine {
        font-size: 30px;
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 10px;
        font-weight: 700;
    }

    .NewMagazineimg{
    width: 100%;
    margin-top: 25px;
    border-radius: 10px;
    cursor: pointer;
    }


}