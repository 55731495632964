@import "../globals.scss";


.Advertisement {
    // max-width: 340px;
    width: 100%;







    .AdvertisingRates {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 40px;
        height: 100%;
        justify-content: center;
        position: relative;

        .advertising-rates {
            width: 100%;
            max-width: 1200px;
        }

        .ratesTab {
            display: flex;
            justify-content: center;
            padding: 20px 0px;
        }

        .ratesTab button {
            background-color: transparent;
            /* Green */
            border: solid 1px #4cc8f4;
            color: #4cc8f4;
            padding: 15px 32px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            font-weight: bold;
            margin: 4px 2px;
            transition-duration: 0.4s;
            cursor: pointer;
            flex: 1;
        }

        .ratesTab button:hover {
            // background-color: #45a049;
        }

        .ratesTab button.active {
            background-color: #4cc8f4;
            color: white;
        }

        .tab-content {
            text-align: center;
        }

        .ratesTabTitle {
            padding: 30px;
            font-size: 40px;
            font-weight: 600;
            color: #4cc8f4;
        }

        .downloadLink {
            text-decoration: none;
            color: var(--text-color);

            &:hover {
                text-decoration: underline;
            }
        }

        .advertiseTitle {
            margin-right: 20px;
            color: #4cc8f4;
            font-weight: lighter;
        }
        
        .agreementButtonContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }
        
        .workOrderForm {
            display: inline-block;
            color: var(--text-color);
            padding: 10px 40px;
            text-decoration: none;
            font-size: 18px;
            transition: background-color 0.3s ease;
            margin: 15px 20px;
            border: 1px solid #4cc8f4;
        }
        
        .workOrderForm:hover {
            background-color: #5f6f52;
        }

        @media screen and (max-width: 768px) {
            .advertiseTitle {
                flex-basis: 100%; /* Take full width */
                text-align: center; /* Center align */
                margin-bottom: 10px; /* Add some space below the title */
            }
        
            .workOrderForm {
                margin: 10px; /* Reduce margin for smaller screens */
            }
        }
        

        .pdfIcon {
            font-size: 20px;
        }

        .web-print-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 14px;
        }



        .content-item {
            flex: 1 0 100%;
            margin: 26px;
            display: flex;
            align-items: center;
        }

        .image-container,
        .ratesDescription {
            flex: 1;
        }

        .image-container img {
            max-width: 100%;
            height: auto;
        }

        .ratesDescription {
            padding-left: 80px;
            max-width: 35%;
        }

        .ratesDescription h2 {
            margin-bottom: 20px;
            font-size: 30px;
            font-weight: 100;
            color: #4cc8f4;
            text-align: left;
        }

        .ratesDescription p {
            margin-bottom: 10px;
            font-size: 18px;
            text-align: left;
        }




        // Starter packages
        .info-container {
            width: 480px;
            display: inline-block;
            margin: 20px;
            vertical-align: top;
            text-align: left;
        }

        @media only screen and (max-width: 1024px) {
            .info-container {
                width: 100%;
                margin: 0;
            }
        }

        .info-content {
            padding: 20px;
        }

        .info-content h2 {
            font-size: 24px;
            margin-bottom: 10px;
            color: #4cc8f4;
            font-weight: normal;
        }

        .info-content p {
            font-size: 16px;
            margin-bottom: 10px;
        }

        .info-content ul li {
            line-height: 24px;
            margin-bottom: 10px;
            font-size: 18px;
        }





        // 22westmedia coverage map
        .coverageMap h2 {
            font-size: 30px;
            text-align: left;
            margin-bottom: 10px;
            color: #4cc8f4;
            font-weight: normal;
            padding-left: 20px;
        }

        .coverageMap p {
            text-align: left;
            font-size: 20px;
            line-height: 28px;
            padding: 0px 20px;
        }

        .coverageMap img {
            margin: 30px 0px;
            max-width: 100%;
            height: auto;
        }

        .coverageMap ul{
            text-align: left;
            line-height: 30px;
            font-size: 18px;
        }

        .coverageMap ul{
            text-align: left;
            line-height: 30px;
            font-size: 18px;
        }

        .statsLink{
            text-decoration: underline;
            color: var(--text-color);
        }





        @media only screen and (min-width: 768px) {
            .content-item {
                flex: 0 0 100%;
            }

            .image-container {
                flex: 0 0 56%;
            }

            .ratesDescription {
                flex: 0 0 44%;
            }
        }

        @media only screen and (max-width: 768px) {
            .content-item {
                flex-direction: column;
            }

            .image-container img {
                max-width: 100%;
                height: auto;
            }

            .ratesDescription {
                margin: 0 40px;
                max-width: 100%;
                padding: 0;
            }
        }


        @media only screen and (max-width: 768px) {
            .ratesTab button {
                padding: 8px 16px;
                font-size: 12px;
            }
        }










    


        .advertisingUpdateTitle{
            background: linear-gradient(to right, #4CC8F4, #ed00ff);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            font-size: 28px !important;
            margin: 30px 0px 20px 0px;
            font-weight: 600;
            line-height: 40px !important;
        }
        .advertisingUpdateTitle span{
            font-size: 20px;
        }











        // 2nd tab: radio advertising rates

        .rate-cost-table {
            width: 60%;
            border-collapse: collapse;
            margin: 40px auto;
            border-style: dashed;
        }

        .columnHeading {
            color: #4cc8f4;
            font-size: 24px;
            padding: 16px 0 !important;
        }

        .rate-cost-table th,
        .rate-cost-table td {
            border-style: groove;
            padding: 12px;
            text-align: center;
        }

        @media only screen and (max-width: 768px) {

            .rate-cost-table th,
            .rate-cost-table td {
                padding: 8px;
            }

            .rate-cost-table {
                width: 75%;
            }
        }


        .PieChart {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .PieChart div {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }

        @media (max-width: 768px) {
            .PieChart div {
                flex-wrap: wrap;
            }
        }

        .populationData {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .populationData div {
            flex: 1;
            padding: 20px;
            text-align: left;
            margin-left: 10%;
        }

        .hr-rule {
            border: 1px solid #4cc8f4;
            width: 100%;
            margin: 40px 0px;
        }

        .title {
            color: #4cc8f4;
        }

        .title span {
            color: var(--text-color);
        }

        @media (max-width: 768px) {
            .populationData {
                flex-wrap: wrap;
                flex-direction: column;
            }
        }


        .aboutReaders {
            text-align: left !important;
        }

        .aboutReaders ul li {
            padding: 8px;
            font-size: 20px;
        }









        .agreementAccordion {
            width: 100%;
        }
        
        .agreementAccordionItem {
            margin-bottom: 40px;
        }
        
        .agreementAccordionTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: transparent;
            color: var(--text-color);
            padding: 10px;
            cursor: pointer;
            border-bottom: 4px solid #4cc8f4;
            text-align: left;
            width: 100%;
            font-size: 20px;
        }
        
        .agreementAccordionSymbol {
            margin-left: 10px;
        }
        
        .agreementAccordionContent {
            padding: 10px;
            display: none;
            text-align: left;
        }

        .agreementAccordionContent p{
            font-size: 16px;
        }
        
        .agreementAccordionContent.open {
            display: block;
            border: 1px solid var(--text-color);
        }
        


    }

}





.table-container {
    overflow-x: auto;
    scrollbar-width: thin;
}

.responsive-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    min-width: 600px;
}

.responsive-table th,
.responsive-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    width: 33%;
}

.responsive-table th {
    background: linear-gradient(to right, #4cc8f473, #ed00ff4d);
    font-weight: bold;
}

.responsive-table tbody tr:hover {
    transition: background-color 0.3s ease;
    transform: scale(1.02);
}

@media screen and (max-width: 600px) {
    .responsive-table th,
    .responsive-table td {
        padding: 8px;
    }
}

.responsive-table tbody tr {
    transition: transform 0.3s ease;
}
