// ShowModal.scss
// @import '@mui/material/Modal';
// @import '@mui/material/Box';
// @import '@mui/material/Typography';
// @import '@mui/material/Avatar';

.CustomModal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.CustomBox {
    position: absolute;
    width: 400px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
        outline: none;
    }
    
    

    .CustomTypography {
        text-align: center;
        margin-top: 8px;

        &.subtitle1 {
            color: #757575;
        }

        &.body2 {
            margin-top: 16px;
            // color: #212121; 
        }
    }

}
.showDescription-Header{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: auto;
    gap: 30px;

    .CustomAvatar {
        display: flex;
        width: 80px;
        height: 80px;
        margin: 0 auto; 
    }

    .showDescription-Author{
        display: flex;
        position: relative;
        justify-content: start;
        flex-direction: column;

        h6{
            text-align: start;
        }
    }
}