@import "../globals.scss";


.AboutStaff{
    max-width: 340px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 450px;
    border: 1px solid #4CC8F4;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;

    .AboutStaffimg{
        width: 340px;
        height: 340px ;
        object-fit: contain;
    }

    .AboutStaffName{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px;
        font-weight: 600;
        margin-top: 10px;
    }

    .AboutStaffNamest{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 400;
        margin-top: 10px;
    }

    .iconStr{
        display: flex;
        margin-top: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .iconMain{
            margin: 2px 2px;
        }

    }
    
}