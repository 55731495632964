@import "../globals.scss";

.show-slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  position: relative;

  .darkmode {
    background-color: #404040 !important;
    transition: all 0.5s;
  }
  .whitemode{
    color: #404040 !important;
  }

  .show-slider-main {
    width: 100%;
    max-width: 1200px;
    min-height: 200px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    transition: all 0.5s;
    padding: 40px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    @media screen and (max-width: 700px) {
      padding: 20px 15px;
      width: 85%;
    }
  }

  .show-slider-title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: cyan;
  }

  .show-slider-card {
    width: 100%;
    height: 200px;
    display: flex;
    gap: 10px;
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  .show-slider-image {
    width: 80%;
    height: 80%;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s;
  }

  .show-slider-image:hover {
    transform: scale(1.05); 
    transition: all 0.3s;

  }

  .show-slider-carousel {
    margin-top: 14px;
  }

  .show-slider-link {
    text-decoration: none;
    width: 100%;
    cursor: pointer;
    display: flex;
    height: 100%;
  }
}
