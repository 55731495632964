@import "../globals.scss";

.LatestArticles{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative;
    
    .darkmode{
        background-color: #404040 !important;
        transition: all 0.5s;

    }
    .navLinkDesign{
        text-decoration: none !important;
    }


    .LatestArticlesMain{
        width: 90%;
       
        max-width: 1200px;
        min-height:500px;
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        transition: all 0.5s;
        padding: 40px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        @media screen and (max-width: 700px) {
         padding: 20px 15px;
        }
    }
    .LatestArticlesTitle{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 30px;
        font-weight: 600;
    }

    .LatestArticlesCards{
        min-height: 430px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }



    .ArticlesCardslightmode{
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

  

    .LatestArticlesShowBtn{
        display: flex;
        justify-content: center;
        align-items: center;

        .btnShowmore{
            background-color: #515050;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18px;
            padding: 10px 20px;
            border-radius: 20px;
            cursor: pointer;
            color: white;

        }

        
    }


}

.ArticlesCards{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 400px;
    background-color: #515050;
    margin: 20px 15px;
    border-radius: 10px;
    padding: 15px;
    @media screen and (max-width: 700px) {
       margin: 10px 0px ;
       }
    .ArticlesCardsimage{
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
    }
    .ArticlesCardstopic{
        margin-top: 10px;
        display: flex;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height:44px;
        overflow: hidden;
        text-overflow: ellipsis; 
        text-align: left;
        font-size: 18px;
        font-weight: 200;
        color: $Blue-color;

    }

    .ArticlesCardsText{
        font-size: 24px;
        margin-top: 10px;
        height: 140px;
        font-weight: 600;
        overflow: hidden;
        display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
        color: var(--text-color);
    }
    
}


.LatestArticlesMODAL{
    height: 500px;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

